<template>
    <div>
        <FullCalendar 
            :events="events"
            :options="options"
            @click="handleDateClick"
            ref="fullCalendar"
        >
            <template v-slot:eventContent='arg'>
                <b>{{ arg.event.title }}</b>
                <b>AAAAAAAAAA</b>
            </template>
        </FullCalendar>
    </div>
</template>

<script>
import { ref } from 'vue';
import '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Axios from 'axios';
import { useToast } from 'primevue/usetoast';
import moment from 'moment';
export default {
    setup() {
        const events =  ref(null);
        const toast = useToast();

        const currentDate = ref(new Date());
        const startDate = ref();
        const endDate = ref();

        const fullCalendar = ref(null);

        startDate.value = new Date(currentDate.value.getFullYear(), currentDate.value.getMonth(), 1);
        startDate.value = moment(startDate.value).format("YYYY-MM-DD");

        endDate.value = new Date(currentDate.value.getFullYear(), currentDate.value.getMonth()+1, 0);
        endDate.value = moment(endDate.value).format("YYYY-MM-DD");

        const getEvents = () =>{
            Axios
                .get("api/operator/tour/calendar",{
                    params: {
                        startDate: startDate.value,
                        endDate: endDate.value,
                    }
                })
                .then((res) =>{
                    if (res.data.response == "success") {
                        events.value = res.data.data;
                        for(let event of events.value){
                            event.start = event.start_date;
                            event.end = event.end_date;
                            event.url = 'tours/details/'+ event.slug;
                        }
                    }else{
                        console.log(res);
                        showToast("error", "Error", res.data.message);
                    }
                })
                .catch((err) =>{
                    console.log(err);
                    showToast("error", "Error", "Could not fetch data. Try again!");
                });
        }
        getEvents();

        const options =  ref({
            plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
            // initialDate : '2017-02-01',
            headerToolbar: {
                left: 'prev next',
                center: 'title',
                right: 'today',
            },
                editable: true,
                selectable:true, 
                selectMirror: true, 
                dayMaxEvents: true,
        });

        const handleDateClick = () =>{
            let selectedDate = fullCalendar.value.calendar.currentData.viewTitle;
            selectedDate = new Date(selectedDate);

            let newStartDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
            newStartDate = moment(newStartDate).format("YYYY-MM-DD");

            let newEndDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth()+1, 0);
            newEndDate = moment(newEndDate).format("YYYY-MM-DD");

            if ((newStartDate != startDate.value) || (newEndDate != endDate.value)) {
                startDate.value = newStartDate;
                endDate.value = newEndDate;

                getEvents();
            }
        }

        const showToast = (severity, summary, detail) => {
            toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
        };

        return { options, events, handleDateClick, fullCalendar };
    }
}
</script>

<style scoped>
@media screen and (max-width: 960px) {
    ::v-deep(.fc-header-toolbar) {
        display: flex;
        flex-wrap: wrap;
    }
}
</style>